import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import GeneratorView from "@/GeneratorView";

const routes = [
  {
    path: '/',
    redirect: '/generate'
  },
  {
    path: '/generate',
    component: GeneratorView
  }
]

const router = createRouter({
  history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
  routes
})

export default router
