<template>
  <div class="flex flex-column min-h-full">
    <h2 class="m-0 pt-3 pl-3">QR-RECHNUNG</h2>
    <p class="mt-0 mb-0 pl-3">mit einem Klick</p>
    <div class="flex flex-column align-items-center justify-content-center flex-grow-1 mt-2">
      <div>
        <div class="flex flex-column">
          <label for="address">Adresse</label>
          <Textarea id="address" v-model="address" :autoResize="true" rows="5" cols="30" placeholder="Max Muster
  Musterstrasse 1
  8000 Musterlingen
  CH"/>
        </div>
        <div class="flex flex-column mt-3">
          <label for="iban">IBAN</label>
          <InputText id="iban" v-model="iban"/>
        </div>
        <div class="flex flex-column mt-3">
          <label for="amount">Betrag</label>
          <InputNumber id="amount" v-model="amount" mode="currency" currency="CHF" locale="de-CH" />
        </div>
        <div class="flex flex-column mt-3">
          <label for="details">Bemerkungen (optional)</label>
          <InputText id="details" v-model="details" />
        </div>
        <div class="flex flex-column mt-3 align-items-end">
          <Button :icon="buttonIcon||'pi pi-download'" :class="buttonClass||'p-button-plain'" @click="generatePDF()" label="Rechnung" />
        </div>
      </div>
    </div>
    <div class="flex flex-row align-items-end justify-content-end p-1">
      <a href="https://codefury.ch" target="_blank" class="no-underline text-black-alpha-90">by code:fury</a>
    </div>
  </div>
  <Toast></Toast>
</template>

<script>
import axios from "axios";
import {Buffer} from "buffer";

export default {
  name: "GeneratorView",
  data() {
    return {
      address:"Max Muster\n" +
          "Musterstrasse 1\n" +
          "8000 Musterlingen\n" +
          "CH",
      iban:"CH02 0070 0110 0003 8789 6",
      amount:1,
      details:"Rechnung #1",
      buttonIcon:null,
      buttonClass:null,
      generatorBaseUrl:"https://api.qr.codefury.ch"//http://localhost:3555
    }
  },
  methods: {
    updateButtonStyle(downloading=false) {
      this.buttonIcon=downloading ? "pi pi-spin pi-spinner" : null;
      this.buttonClass=downloading ? "p-button-success" : null;
    },
    async generatePDF() {
      let response = null;
      this.updateButtonStyle(true);
      let payment = {
        creditor:{
          address:this.address.split(/\n/),
          iban:this.iban
        },
        amount:this.amount,
        details:this.details
      };
      let args = Buffer.from(JSON.stringify({payment})).toString("base64")
      try {
        response = await axios.post(`${this.generatorBaseUrl}/invoice`,{
          args
        });
      } catch (err) {
        this.updateButtonStyle();
        return this.$toast.add({severity:'error', summary: 'Fehler', detail:err.response && err.response.data && err.response.data.error && err.response.data.error.message || "Server error", life: 3000})
      }
      window.open(`${this.generatorBaseUrl}/download/${response.data.label}`);
      this.updateButtonStyle();
      /*let {data} = await axios({method:"get",url:"http://localhost:3435/download/invoice/"+response.data.uuid,responseType: "blob"});
      saveAs(data,args.id+".pdf");*/
    },
  }
}
</script>

<style scoped>
.p-button {
  background: #f3586f!important;
  border: 1px solid #f3586f!important;
}
.p-button:enabled:hover {
  background: #da405b!important;
  border-color: #da405b!important;
}
.p-button:focus {
  box-shadow: 0 0 0 0.2rem #ff8395!important;
}
</style>