import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import router from './router';
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";

const app = createApp(App);
app
    .use(router).use(PrimeVue).use(ToastService)/*.use(Vue3Storage).use(ConfirmationService)*/
    .component("Dialog",Dialog)
    .component("FileUpload",FileUpload)
    .component("InputText",InputText).component("InputNumber",InputNumber).component("Button",Button).component("Textarea",Textarea)
    .component("Toast",Toast)
router.isReady().then(()=>{
    app.mount('#app')
});
